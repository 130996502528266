<template>
    <div>
      <input
        type="text"
        placeholder="Поиск по офисам"
        v-model="search"
        class="search-input"
      />
  
      <!-- Горизонтальная таблица для десктопа -->
      <table v-if="!isMobile" class="table">
        <thead>
          <tr>
            <th>Офис</th>
            <th>Город</th>
            <th v-for="date in dates" :key="date">{{ date }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(office, index) in filteredOffices"
            :key="office.id"
            :class="{'table-row-alt': index % 2 === 1}"
          >
            <td>{{ office.name }}</td>
            <td>{{ office.city }}</td>
            <td
              v-for="date in dates"
              :key="date"
              :class="{'holiday': office.schedule[date] === 'Выходной'}"
            >
              {{ office.schedule[date] || "Нет данных" }}
            </td>
          </tr>
        </tbody>
      </table>
  
      <!-- Вертикальная таблица для мобильных -->
      <div v-else class="mobile-table">
        <div
          class="mobile-table__item"
          v-for="office in filteredOffices"
          :key="office.id"
        >
          <h4 class="table-heading">{{ office.name }}</h4>
          <p>{{ office.city }}</p>
          <div v-for="date in dates" :key="date" class="mobile-table__date">
            <span class="date">{{ date }}</span>
            <span
              class="status"
              :class="{'holiday': office.schedule[date] === 'Выходной'}"
            >
              {{ office.schedule[date] || "Нет данных" }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      offices: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        search: "",
        isMobile: window.innerWidth <= 768,
      };
    },
    computed: {
  dates() {
    const allDates = this.offices
      ? this.offices.flatMap((office) => Object.keys(office.schedule || {}))
      : [];
    return [...new Set(allDates)];
  },
  filteredOffices() {
    return (this.offices || [])
      .filter((office) =>
        office.name.toLowerCase().includes(this.search.toLowerCase())
      )
      .sort((a, b) => {
        // Выводим "Москва" и "Санкт-Петербург" вперед
        const priorityCities = ["Москва", "Санкт-Петербург"];
        
        const aCityPriority = priorityCities.includes(a.city) ? 0 : 1;
        const bCityPriority = priorityCities.includes(b.city) ? 0 : 1;

        // Сначала сортируем по приоритету городов
        if (aCityPriority !== bCityPriority) {
          return aCityPriority - bCityPriority;
        }

        // Если города с одинаковым приоритетом, сортируем по алфавиту
        return a.city.localeCompare(b.city);
      });
  },
},

    mounted() {
      // Обновляем isMobile при изменении размера окна
      window.addEventListener("resize", this.checkMobile);
    },
    beforeUnmount() {
      window.removeEventListener("resize", this.checkMobile);
    },
    methods: {
      checkMobile() {
        this.isMobile = window.innerWidth <= 768;
      },
    },
  };
  </script>
  
  <style scoped>
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  .table th,
  .table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
  }
  .search-input {
    margin-bottom: 10px;
    padding: 8px;
    width: 100%;
  }
  
  /* Чередование цвета для строк таблицы */
  .table-row-alt {
    background-color: #f9f9f9;
  }
  
  /* Зеленый цвет для текста "Выходной" */
  .holiday {
    color: green;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    /* Скрываем десктопную таблицу на мобильных */
    .table {
      display: none;
    }
    /* Стили для мобильной версии */
    .mobile-table__item {
      padding: 10px;
      border: 1px solid #ddd;
      margin-bottom: 10px;
    }
    .mobile-table__date {
      display: flex;
      justify-content: space-between;
      padding: 5px 0;
    }
    .date {
      font-weight: bold;
    }
    .table-heading {
      font-size: 1.4em;
    }
  }
  </style>
  