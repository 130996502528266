<template>
    <div class="sms-code-component">
      <!-- Поле для ввода SMS-кода -->
      <div class="form__item sms_item">
        <label for="sms_code" class="form__label">Введите код из смс</label>
        <div class="form__code">
          <input
            type="text"
            v-model="smsCode"
            class="form-control separate-sms-code"
            :class="{ 'is-invalid': form.errors.has('sms_code') }"
            :disabled="!enableSmsCodeField"
            id="sms_code"
            @input="emitSmsCode"
            placeholder="Введите код"
          />
          <button
            class="form__btn btn"
            @click="sendSmsCode"
            :class="{ blue_border: !isSmsSubmitEnabled }"
            :disabled="!isAvailableToSendSMS || !isSmsSubmitEnabled || cooldown > 0"
          >
            {{ cooldown > 0 ? `Повторно через ${cooldown}s` : 'Получить код' }}
          </button>
        </div>
        <HasError :form="form" field="sms_code"></HasError>
        <p class="form__info">
          Вы получите СМС с кодом подтверждения на указанный мобильный телефон
        </p>
        <p class="form__info">
          Нажимая кнопку "Получить код" или "Отправить", Вы даёте согласие на
          <a target="_blank" href="https://cdn.molbulak.ru/data/docs/agree/soglasen.pdf/">обработку персональных данных</a>
        </p>
      </div>
    </div>
  </template>
  
  <script>
  import { Form } from 'vform';
  import axios from 'axios';
  
  export default {
    name: "SmsCodeComponent",
    props: {
      phone: {
        type: String,
        required: true,
      },
      phone_code: {
        type: String,
        required: true,
      },
      siteKey: {
        type: String,
        default: '', // Можно передать ключ капчи, если требуется
      },
    },
    data() {
      return {
        form: new Form({
          sms_code: '',
        }),
        smsCode: '',
        encryptedSmsCode: '',
        isSmsSubmitEnabled: true,
        enableSmsCodeField: false,
        cooldown: 0,
        cooldownInterval: null,
      };
    },
    computed: {
      isAvailableToSendSMS() {
        if (!this.phone || !this.phone_code) {
          return false;
        }
        const phoneLength = this.phone.length;
        switch (this.phone_code) {
          case '+7':
            return phoneLength >= 15;
          case '+996':
          case '+998':
          case '+992':
            return phoneLength >= 13;
          default:
            return false;
        }
      },
    },
    watch: {
      phone(newVal) {
        // Можно добавить дополнительные действия при изменении номера телефона
      },
      phone_code(newVal) {
        // Можно добавить дополнительные действия при изменении кода телефона
      },
    },
    methods: {
      sendSmsCode() {
        if (this.isSmsSubmitEnabled && this.isAvailableToSendSMS && this.cooldown === 0) {
          this.isSmsSubmitEnabled = false; // Блокируем кнопку отправки
          this.$recaptcha()
            .then((token) => {
              // Отправляем запрос на получение SMS-кода
              axios
                .post('/send-sms-code', {
                  phone_code: this.phone_code,
                  phone: this.phone,
                  token: token,
                })
                .then((response) => {
                  if (response.data.success) {
                    this.encryptedSmsCode = response.data.code;
                    this.$emit('update:encryptedSmsCode', this.encryptedSmsCode);
                    this.$snotify.success("СМС с кодом подтверждения отправлено!", { timeout: 3000 });
                    this.enableSmsCodeField = true; // Разблокируем поле ввода кода
                    this.startCooldown(); // Запускаем таймер блокировки повторной отправки
                  } else {
                    this.$snotify.warning(response.data.message || "Ошибка отправки СМС", { timeout: 3000 });
                    this.isSmsSubmitEnabled = true; // Разблокируем кнопку при ошибке
                  }
                })
                .catch((error) => {
            // Проверяем, есть ли ответ от сервера с текстом ошибки
            if (error.response && error.response.data && error.response.data.message) {
              this.$snotify.error(error.response.data.message, { timeout: 9000 });
            } else {
              this.$snotify.error("Ошибка отправки СМС", { timeout: 9000 });
            }
            this.isSmsSubmitEnabled = true; // Разблокируем кнопку при ошибке
          });
      })
            .catch(() => {
              this.$snotify.error("Ошибка капчи", { timeout: 3000 });
              this.isSmsSubmitEnabled = true; // Разблокируем кнопку при ошибке капчи
            });
        }
      },
      emitSmsCode() {
        //this.$emit('update:smsCode', this.smsCode);
        this.$emit('update:smsCode', String(this.smsCode));
      },
      startCooldown() {
        this.cooldown = 60; // 60 секунд блокировки
        this.cooldownInterval = setInterval(() => {
          if (this.cooldown > 0) {
            this.cooldown -= 1;
          } else {
            clearInterval(this.cooldownInterval);
            this.cooldownInterval = null;
            this.isSmsSubmitEnabled = true; // Разблокируем кнопку после таймаута
          }
        }, 1000);
      },
    },
    beforeUnmount() {
      if (this.cooldownInterval) {
        clearInterval(this.cooldownInterval);
      }
    },
  };
  </script>
  
  <style scoped>
  
  .sms_item {
    display: flex;
                    justify-content: right;
                    align-items: center;
                    margin-bottom: 10px;
                    flex-direction: row;
                    flex-wrap: wrap;
  }
  
  .form__code {
    display: flex;
    align-items: center;
  }
  
  .form__code input {
    flex: 1;
    margin-right: 10px;
  }
  
  .form__btn {
    flex-shrink: 0;
  }
  </style>
  
  