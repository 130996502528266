<template>
    <form v-if="!formSubmitted" @submit.prevent="sendLoanConsultationForm" class="form form--consultation">
        <div class="form__item">
            <label class="form__label">Категория</label>
            <select class="form__select select" v-model="loanConsultationForm.category">
                <option v-for="category in categories" :value="category.title">{{ category.title }}</option>
            </select>
            <HasError :form="loanConsultationForm" field="category"></HasError>
        </div>

        <div class="form__item">
            <label for="question" class="form__label">Вопрос</label>
            <textarea class="form__textarea" id="question" placeholder="Вводите текст сюда"
                      v-model="loanConsultationForm.question"></textarea>
            <HasError :form="loanConsultationForm" field="question"></HasError>
        </div>

        <div class="form__item">
            <label for="full_name" class="form__label">ФИО <sup>*</sup></label>
            <input type="text" class="form__input" id="full_name" required v-model="loanConsultationForm.full_name">
            <HasError :form="loanConsultationForm" field="full_name"></HasError>
        </div>

        <div class="form__item">
            <label for="email" class="form__label">Электронная почта <sup>*</sup></label>
            <input type="email" class="form__input" id="email" required
                   placeholder="Напишите адрес своей электронной почты" v-model="loanConsultationForm.email">
            <HasError :form="loanConsultationForm" field="email"></HasError>
        </div>

        <!-- Компонент для ввода номера телефона -->
        <div class="form__item">
            <PhoneInputComponent
                :phone="loanConsultationForm.phone"
                :phone_code="loanConsultationForm.phone_code"
                @update:phone="loanConsultationForm.phone = $event"
                @update:phone_code="loanConsultationForm.phone_code = $event"
            />
            <HasError :form="loanConsultationForm" field="phone"></HasError>
        </div>

        <!-- Компонент для ввода SMS-кода -->
        <div class="form__item">
            <SmsCodeComponent
                :phone="loanConsultationForm.phone"
                :phone_code="loanConsultationForm.phone_code"
                @update:smsCode="loanConsultationForm.sms_code = $event"
                @update:encryptedSmsCode="loanConsultationForm.encrypted_sms_code = $event"
            />
            <HasError :form="loanConsultationForm" field="sms_code"></HasError>
        </div>

        <div class="form__item">
            <div class="g-recaptcha" :data-sitekey="siteKey" data-expired-callback="recaptcha_expired"
                 data-callback="recaptcha_callback"></div>
        </div>
        <hr>
        
        <div class="form__item">
            <button class="form__btn btn btn--green" type="submit" :disabled="!isFormValid">ОТПРАВИТЬ</button>
        </div>
    </form>

    <!-- Сообщение при успешной отправке -->
    <div v-else>
        <div style="background-color: #f6f6f6; width: 100%; padding: 2em; border-radius: 10px;">
            <h2>Ваш запрос успешно отправлен</h2>
            <p>Мы свяжемся с Вами в ближайшее время!</p>
        </div>
    </div>
</template>

<style scoped>
.form--consultation {
    background-color: #f3f3f3;
    padding: 1.5em;
    border-radius: 17px;
    .form__item {
        .form__code {
            .btn {
                width: 100%;
                height: 40px;
                border: 1px solid #D8D8D8;
                border-radius: 5px;
                padding: 0 10px;
                font-size: 0.9em;
                background: #F5F5F5;
                color: #303030;
                cursor: pointer;
                margin-left: 15px;

                &.blue_border {
                    border: 1px solid #4b8c30 !important;
                }
            }
        }

        .form__phone {
            display: flex;
            justify-content: space-between;

            .form__select {
                width: 30% !important;
                max-width: 30% !important;
            }

            .phone_number_container {
                width: 69% !important;
                max-width: 69% !important;

                .form__input {
                    max-width: 100% !important;
                }
            }
        }
    }
}
::v-deep .phone-separate {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}
::v-deep .phone-separate-label{
    width: 100%;
}

::v-deep .phone-separate-fields {
    margin-left: 0%;
    width: 100%;
}
::v-deep .phone-separate-fields div {
    width: 100%;
}

::v-deep .phone-separate-selector {
    padding-left: 1em;
    width: 21%;
}

::v-deep .separate-phone-field {
    width: 100%;
}

::v-deep .sms_item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
::v-deep .sms_item .form__label {
    width: 100%;
}
::v-deep .separate-sms-code {
    margin-left: 0%;
}



::v-deep .sms-phone-select {
    width: 14%;
    margin-right: 1px;
    margin-left: 5%;
}

::v-deep .sms-phone-input {
    width: 50%;
}

::v-deep .sms-code-input {
    margin-right: 1% !important;
    margin-left: 6% !important;
    width: 30% !important;
}

@media (max-width: 768px) {
::v-deep .phone-separate-selector {
    width: 30%;
}

}
</style>

<script>
import { Form } from 'vform';
import SmsCodeComponent from './SmsCodeComponent.vue';
import PhoneInputComponent from './PhoneInputComponent.vue';

export default {
    name: "LoanConsultationForm",
    components: {
        SmsCodeComponent,
        PhoneInputComponent,
    },
    props: ['is-mobile', 'categories'],
    data() {
        return {
            siteKey: process.env.GOOGLE_RECAPTCHA_SITE_KEY,
            loanConsultationForm: new Form({
                category: "Аренда жилья в РФ",
                question: '',
                full_name: '',
                email: '',
                phone: '',
                phone_code: "+7",
                sms_code: '',
                encrypted_sms_code: '',
                token: '',
                agree: true,
            }),
            agreeWithTerm: false,
            formSubmitted: false,
        }
    },
    computed: {
        isFormValid() {
            return this.loanConsultationForm.category &&
                this.loanConsultationForm.question &&
                this.loanConsultationForm.full_name &&
                this.loanConsultationForm.email &&
                this.loanConsultationForm.phone &&
                this.loanConsultationForm.sms_code &&
                this.loanConsultationForm.encrypted_sms_code;
                //this.agreeWithTerm;
        }
    },
    methods: {
        async sendLoanConsultationForm() {
            if (!this.isFormValid) {
                this.$snotify.warning('Пожалуйста, заполните все обязательные поля.', { timeout: 3000 });
                return;
            }

            try {
                const formData = {
                    category: this.loanConsultationForm.category,
                    question: this.loanConsultationForm.question,
                    full_name: this.loanConsultationForm.full_name,
                    email: this.loanConsultationForm.email,
                    phone: this.loanConsultationForm.phone.replace(/[^0-9]/g, ''),
                    phone_code: this.loanConsultationForm.phone_code.replace('+', ''),
                    sms_code: String(this.loanConsultationForm.sms_code),
                    encrypted_sms_code: this.loanConsultationForm.encrypted_sms_code,
                    token: this.loanConsultationForm.token,
                    agree: this.agreeWithTerm,
                };

                const response = await axios.post('/send-loan-consultation-form', formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                });

                if (response.data.status) {
                    this.$snotify.success(response.data.message, { timeout: 3000 });
                    this.formSubmitted = true;
                    this.loanConsultationForm.reset();
                } else {
                    this.$snotify.error(response.data.message, { timeout: 3000 });
                }
            } catch (error) {
                console.error('Ошибка при отправке формы:', error);
                if (error.response) {
                    const statusCode = error.response.status;
                    const data = error.response.data;

                    if (statusCode === 422) {
                        if (data.errors) {
                            this.loanConsultationForm.errors.set(data.errors);
                            this.$snotify.error('Пожалуйста, исправьте ошибки в форме.', { timeout: 3000 });
                        } else if (data.message) {
                            this.$snotify.error(data.message, { timeout: 3000 });
                            this.loanConsultationForm.errors.set({ sms_code: [data.message] });
                        } else {
                            this.$snotify.error('Произошла ошибка при отправке данных. Пожалуйста, попробуйте снова позже.', { timeout: 3000 });
                        }
                    } else {
                        this.$snotify.error(data.message || 'Произошла ошибка при отправке данных. Пожалуйста, попробуйте снова позже.', { timeout: 3000 });
                    }
                } else {
                    this.$snotify.error('Произошла ошибка при отправке данных. Пожалуйста, попробуйте снова позже.', { timeout: 3000 });
                }
            }
        },
    }
}
</script>