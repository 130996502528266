<template>
    <form v-if="!formSubmitted" @submit.prevent="SendContactForm" class="form form--contact">
        <div class="form__item">
            <label class="form__label">Тема обращения</label>
            <select class="form__select select" v-model="contactForm.subject"
                :class="{ 'is-invalid': contactForm.errors.has('subject') }">
                <option disabled value="">Выберите тему</option>
                <option value="Вопросы по займам">Вопросы по займам</option>
                <option value="Миграция">Миграция</option>
                <option value="Жалоба">Жалоба</option>
                <option value="Другое">Другое</option>
            </select>
            <span v-if="contactForm.errors.has('subject')" class="text-danger">{{ contactForm.errors.get('subject')
                }}</span>
        </div>

        <div class="form__item">
            <label for="question" class="form__label">Обращение</label>
            <textarea class="form__textarea" v-model="contactForm.body_text" id="question"
                placeholder="Вводите текст сюда"
                :class="{ 'is-invalid': contactForm.errors.has('body_text') }"></textarea>
            <span v-if="contactForm.errors.has('body_text')" class="text-danger">{{ contactForm.errors.get('body_text')
                }}</span>
        </div>

        <div class="form__item">
            <label for="full_name" class="form__label">ФИО</label>
            <input class="form__input" id="full_name" placeholder="Введите ваши Фамилию Имя и Отчество"
                v-model="contactForm.full_name" :class="{ 'is-invalid': contactForm.errors.has('full_name') }">
            <span v-if="contactForm.errors.has('full_name')" class="text-danger">{{ contactForm.errors.get('full_name')
                }}</span>
        </div>

        <div class="form__item">
            <label for="email" class="form__label">Электронная почта <sup>*</sup></label>
            <input type="email" class="form__input" id="email" placeholder="Напишите адрес своей электронной почты"
                v-model="contactForm.email" :class="{ 'is-invalid': contactForm.errors.has('email') }">
            <span v-if="contactForm.errors.has('email')" class="text-danger">{{ contactForm.errors.get('email')
                }}</span>
        </div>

        <!-- Компонент для ввода номера телефона -->
        <div class="form__item">
            <PhoneInputComponent :phone="contactForm.phone" :phone_code="contactForm.phone_code"
                @update:phone="contactForm.phone = $event" @update:phone_code="contactForm.phone_code = $event" />
            <HasError :form="contactForm" field="phone"></HasError>
        </div>

        <!-- Компонент для ввода SMS-кода -->
        <div class="form__item">
            <SmsCodeComponent :phone="contactForm.phone" :phone_code="contactForm.phone_code"
                @update:smsCode="contactForm.sms_code = $event"
                @update:encryptedSmsCode="contactForm.encrypted_sms_code = $event" />
            <HasError :form="contactForm" field="sms_code"></HasError>
        </div>

        <div class="form__item">
            <div class="g-recaptcha" :data-sitekey="siteKey" data-expired-callback="recaptcha_expired"
                data-callback="recaptcha_callback"></div>
        </div>
        <hr>
        <div class="form__item">
            <label class="form__check check">
                <input type="checkbox" class="check__input" value="true" v-model="agreeWithTerm" style="display: none;" checked>

               
            </label>
        </div>

        <div class="form__item">
            <button class="form__btn btn btn--green" type="submit" :disabled="!isFormValid">ОТПРАВИТЬ</button>
        </div>
    </form>

    <!-- Сообщение при успешной отправке -->
    <div v-else>
        <div style="background-color: #f6f6f6; width: 67%; padding: 2em;  border-radius: 34px;">
            <h2>Ваше обращение успешно отправлено</h2>
            <p>Мы свяжемся с Вами в ближайшее время!</p>
        </div>
    </div>
</template>

<style scoped>

.form--contact {
    border-radius: 10px;
    background-color: #f6f6f6;
    box-shadow: aliceblue;
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -webkit-box-shadow: 1px 3px 15px 6px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 1px 3px 15px 6px rgba(34, 60, 80, 0.2);
    box-shadow: 1px 3px 15px 6px rgba(34, 60, 80, 0.2);
}

.phone-select {
    width: 30%;
    padding-left: 1em;
}

.phone-input {
    width: 100%;
    max-width: 100% !important;
}

.contact-sms-input {
    width: 50%;
    margin-right: 1em;
}

.btn--green:hover {
    color: black !important;
}
::v-deep .form__label sup {
    color:green;
}

::v-deep .phone-separate {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}
::v-deep .phone-separate-label{
    width: 100%;
}

::v-deep .phone-separate-fields {
    margin-left: 0%;
    width: 100%;
}
::v-deep .phone-separate-fields div {
    width: 100%;
}

::v-deep .phone-separate-selector {
    padding-left: 1em;
    width: 21%;
}

::v-deep .separate-phone-field {
    width: 100%;
}

::v-deep .sms_item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
::v-deep .sms_item .form__label {
    width: 100%;
}
::v-deep .separate-sms-code {
    margin-left: 0%;
}



::v-deep .sms-phone-select {
    width: 14%;
    margin-right: 1px;
    margin-left: 5%;
}

::v-deep .sms-phone-input {
    width: 50%;
}

::v-deep .sms-code-input {
    margin-right: 1% !important;
    margin-left: 6% !important;
    width: 30% !important;
}
</style>
<script>
import { Form } from 'vform';
import SmsCodeComponent from './SmsCodeComponent.vue';
import PhoneInputComponent from './PhoneInputComponent.vue';

export default {
    name: "AboutContactForm",
    components: {
        SmsCodeComponent,
        PhoneInputComponent,
    },
    data() {
        return {
            siteKey: process.env.GOOGLE_RECAPTCHA_SITE_KEY,
            contactForm: new Form({
                subject: '',
                body_text: '',
                full_name: '',
                email: '',
                phone: '',
                phone_code: '+7',
                sms_code: '',
                encrypted_sms_code: '',
                token: '',
                agree: true,
            }),
            agreeWithTerm: false,
            formSubmitted: false,
        };
    },
    computed: {
        isFormValid() {
            return this.contactForm.subject &&
                this.contactForm.body_text &&
                this.contactForm.full_name &&
                this.contactForm.email &&
                this.contactForm.phone &&
                this.contactForm.sms_code &&
                this.contactForm.encrypted_sms_code;
                //this.agreeWithTerm;
        }
    },
    methods: {
        async SendContactForm() {
            if (!this.isFormValid) {
                this.$snotify.warning('Пожалуйста, заполните все обязательные поля.', { timeout: 3000 });
                return;
            }

            try {
                const formData = {
                    subject: this.contactForm.subject,
                    body_text: this.contactForm.body_text,
                    full_name: this.contactForm.full_name,
                    email: this.contactForm.email,
                    phone: this.contactForm.phone.replace(/[^0-9]/g, ''),
                    phone_code: this.contactForm.phone_code.replace('+', ''),
                    sms_code: String(this.contactForm.sms_code),
                    encrypted_sms_code: this.contactForm.encrypted_sms_code,
                    token: this.contactForm.token,
                    agree: this.agreeWithTerm,
                };

                const response = await axios.post('/send-contact-form', formData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                });

                if (response.data.status) {
                    this.$snotify.success(response.data.message, { timeout: 3000 });
                    this.formSubmitted = true;
                    this.contactForm.reset();
                } else {
                    // Отображаем сообщение об ошибке от сервера
                    this.$snotify.error(response.data.message, { timeout: 3000 });
                }
            } catch (error) {
                console.error('Ошибка при отправке формы:', error);
                if (error.response) {
                    const statusCode = error.response.status;
                    const data = error.response.data;

                    if (statusCode === 422) {
                        if (data.errors) {
                            // Если есть ошибки валидации, устанавливаем их и отображаем общее сообщение
                            this.contactForm.errors.set(data.errors);
                            this.$snotify.error('Пожалуйста, исправьте ошибки в форме.', { timeout: 3000 });
                        } else if (data.message) {
                            // Если есть сообщение об ошибке, отображаем его
                            this.$snotify.error(data.message, { timeout: 3000 });
                            // При необходимости, устанавливаем ошибку для конкретного поля
                            // Например, для sms_code
                            this.contactForm.errors.set({ sms_code: [data.message] });
                        } else {
                            // Если нет данных об ошибке, отображаем общее сообщение
                            this.$snotify.error('Произошла ошибка при отправке данных. Пожалуйста, попробуйте снова позже.', { timeout: 3000 });
                        }
                    } else {
                        // Обработка других статусов ошибок
                        this.$snotify.error(data.message || 'Произошла ошибка при отправке данных. Пожалуйста, попробуйте снова позже.', { timeout: 3000 });
                    }
                } else {
                    // Если нет ответа от сервера
                    this.$snotify.error('Произошла ошибка при отправке данных. Пожалуйста, попробуйте снова позже.', { timeout: 3000 });
                }
            }
        },
    }
}
</script>