<template>
    <div class="container">
        <div class="bread d-none d-md-block">
            <a href="#" class="bread__link">Главная страница</a>
            <span class="bread__sep"><i class="icon-arrow_right"></i></span>
            <span class="bread__current">Заявка</span>
        </div>

        <div class="row justify-content-center">
            <div class="col-12">
                <div class="form-container partner-loan-form-container">
                    <div class="row">
                        <!-- Левый блок с информацией -->
                        <div class="col-md-5 d-flex flex-column justify-content-center align-items-start">
                            <h2 class="display-6 partner-heading">Партнёрская программа<br> М Булак</h2>
                            <p class="bg-warning py-3 px-4 rounded text-start mt-4 partner-sub-heading">
                                Приглашайте друзей, копите бонусы и выводите их на карту!
                            </p>
                        </div>

                        <!-- Форма заявки -->
                        <div class="col-md-7">
                            <p class="form-container-title text-center">Анкета</p>

                            <p v-if="formSubmitted"
                                style="font-size: 1.5em; text-align: center; font-weight: 600; margin-top: 10%; color: green;">
                                {{ apiResponseMessage || "Вы уже отправили эту анкету"
                                }}
                            </p>



                            <form v-else @submit.prevent="submitForm" class="form" novalidate>

                                <input type="hidden" name="partner_uuid" v-model="form.partner_uuid">

                                <div class="mb-3">
                                    <div class="d-flex align-items-center">
                                        <label for="fullName" class="form-label" style="width: 30%;">ФИО</label>
                                        <input type="text" id="fullName" v-model="form.fio" class="form-control"
                                            placeholder="Введите ваше ФИО" required style="width: 70%;"
                                            @input="validateFio">
                                    </div>
                                    <div
                                        :class="['invalid-feedback', 'text-end', { 'd-block': errors.fio, 'd-none': !errors.fio }]">
                                        Пожалуйста, введите корректное ФИО (допускаются только буквы, пробелы и знак
                                        "-").
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <div class="d-flex align-items-center">
                                        <label for="city" class="form-label" style="width: 30%;">Город</label>
                                        <select v-model="form.city" class="form-select" id="city" required
                                            style="width: 70%;">
                                            <option value="" selected>Выберите город</option>
                                            <option v-for="city in cities" :key="city.id" :value="city.title">
                                                {{ city.title }}
                                            </option>
                                        </select>
                                    </div>
                                    <div
                                        :class="['invalid-feedback', 'text-end', { 'd-block': errors.city, 'd-none': !errors.city }]">
                                        Пожалуйста, выберите город.
                                    </div>
                                </div>

                                <div class="mb-3">
                                    <div class="d-flex align-items-center">
                                        <label for="language" class="form-label" style="width: 30%;">Выберите язык
                                            общения</label>
                                        <select v-model="form.language" class="form-select" id="language" required
                                            style="width: 70%;">
                                            <option value="" selected>Выберите язык из списка</option>
                                            <option value="Русский">Русский</option>
                                            <option value="Узбекский">Узбекский</option>
                                            <option value="Кыргызский">Кыргызский</option>
                                            <option value="Таджикский">Таджикский</option>
                                        </select>
                                    </div>
                                    <div
                                        :class="['invalid-feedback', 'text-end', { 'd-block': errors.language, 'd-none': !errors.language }]">
                                        Пожалуйста, выберите язык общения.
                                    </div>
                                </div>

                                <!-- Компонент для ввода номера телефона -->
                                <PhoneInputComponent :phone="form.phone" :phone_code="form.phone_code"
                                    @update:phone="form.phone = $event" @update:phone_code="form.phone_code = $event" />

                                <!-- Компонент для отправки SMS -->
                                <SmsCodeComponent :phone="form.phone" :phone_code="form.phone_code"
                                    @update:smsCode="form.sms_code = $event"
                                    @update:encryptedSmsCode="form.encrypted_sms_code = $event" />

                                <!-- Кнопка отправки -->
                                <div class="text-center">
                                    <button type="submit" class="btn btn-primary"
                                        :disabled="!isFormValid">Отправить</button>
                                </div>

                                <div id="errors" class="alert alert-warning mt-3" v-if="formErrors.length">
                                    <p>Заполнены не все поля:</p>
                                    <ul>
                                        <li v-for="error in formErrors" :key="error">{{ error }}</li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SmsCodeComponent from './SmsCodeComponent.vue';
import PhoneInputComponent from './PhoneInputComponent.vue';
import axios from 'axios';

export default {
    name: "PartnerLoanForm",
    components: {
        SmsCodeComponent,
        PhoneInputComponent,
    },
    data() {
        return {
            form: {
                fio: '',
                city: '',
                language: '',
                phone: '',
                phone_code: '+7',
                sms_code: '',
                partner_uuid: '',
                encrypted_sms_code: '',
                token: ''
            },
            cities: [],
            errors: {
                fio: false,
                city: false,
                language: false
            },
            formErrors: [],
            captchaVerified: false,
            formSubmitted: false, // флаг успешной отправки
            apiResponseMessage: '', // сообщение от API
        };
    },
    computed: {
        isFormValid() {
            return this.form.fio &&
                this.form.city &&
                this.form.language &&
                this.form.phone &&
                this.form.sms_code &&
                this.form.encrypted_sms_code &&
                !Object.values(this.errors).some(error => error);
        }
    },
    methods: {
        validateFio() {
            const regex = /^[а-яА-ЯёЁa-zA-Z\s-]+$/u;
            this.errors.fio = !regex.test(this.form.fio);
        },
        async submitForm() {
            this.formErrors = [];

            // Проверка заполненности полей
            if (!this.form.fio) this.formErrors.push('ФИО не заполнено.');
            if (!this.form.city) this.formErrors.push('Город не выбран.');
            if (!this.form.language) this.formErrors.push('Язык общения не выбран.');
            if (!this.form.phone) this.formErrors.push('Телефон не заполнен.');
            if (!this.form.sms_code) this.formErrors.push('SMS код не введен.');
            if (!this.form.encrypted_sms_code) this.formErrors.push('SMS код для проверки отсутствует.');

            // Если есть ошибки, выводим уведомление
            if (this.formErrors.length > 0) {
                this.$snotify.warning('Пожалуйста, верно заполните все поля.', { timeout: 3000 });
                return;
            }

            // Формируем данные для отправки на API
            const formData = {
                fio: this.form.fio,
                city: this.form.city,
                language: this.form.language,
                phone: this.form.phone_code + this.form.phone.replace(/[\s\-\(\)]/g, ''),
                sms_code: String(this.form.sms_code),
                partner_uuid: this.form.partner_uuid,
                encrypted_sms_code: this.form.encrypted_sms_code,
                token: this.form.token
            };

            try {
                // Отправляем данные на API
                const response = await axios.post('/send-partner-loan-anketa-form', formData);
                
                // Получаем RES_TEXT из ответа API и сохраняем его
                this.apiResponseMessage = response.data?.message || 'Ваша анкета отправлена!';

                // Устанавливаем флаг успешной отправки и сохраняем сообщение
                this.formSubmitted = true;
                localStorage.setItem('partnerFormSubmitted', 'true');
                
                // Уведомление с текстом, полученным из API
                this.$snotify.success(this.apiResponseMessage, { timeout: 10000 });
                
            } catch (error) {
                // Логируем ошибку и показываем уведомление
                console.error('Ошибка при отправке анкеты:', error.response ? error.response.data : error);
                if (error.response && error.response.data && error.response.data.message) {
                    const errorMessage = error.response.data.message;
                    this.$snotify.error(`Ошибка: ${errorMessage}`, { timeout: 6000 });
                } else {
                    this.$snotify.error('Произошла ошибка при отправке данных. Пожалуйста, попробуйте снова позже.', { timeout: 6000 });
                }
            }
        },
        checkFormSubmitted() {
            // Проверяем, отправлялась ли форма ранее
            if (localStorage.getItem('partnerFormSubmitted') === 'true') {
                this.formSubmitted = true;
            }
        }
    },
    mounted() {
        // Устанавливаем значение partner_uuid перед проверкой состояния формы
        this.form.partner_uuid = new URLSearchParams(window.location.search).get('uid');

        // Проверка состояния формы
        this.checkFormSubmitted();

        // Загружаем список городов
        axios.get('/api/cities').then(response => {
            this.cities = response.data.filter(city => city.title !== 'Займы не выдаются');
        });

        // Проверка reCAPTCHA
        if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(token => {
                    this.form.token = token;
                    this.captchaVerified = true;
                }).catch(() => { this.captchaVerified = false; });
            });
        }
    }
};
</script>





<style scoped>
::v-deep .phone-separate {
    display: flex;
    align-items: center;
}

::v-deep .phone-separate-fields {
    margin-left: 7%;
    width:100%;
}

::v-deep .phone-separate-selector {
    padding-left: 1em;
    width: 17%;
    margin-left: 7%;
}

::v-deep .separate-phone-field {
    width: 100%;
}

::v-deep .sms_item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

::v-deep .separate-sms-code {
    margin-left: 14%;
}



::v-deep .sms-phone-select {
    width: 14%;
    margin-right: 1px;
    margin-left: 5%;
}

::v-deep .sms-phone-input {
    width: 50%;
}

::v-deep .sms-code-input {
    margin-right: 1% !important;
    margin-left: 6% !important;
    width: 30% !important;
}
</style>
