<template>
     <form @submit.prevent="SendBoardAddForm" class="form form--board--add">
        <div class="form__item">
            <label for="fio" class="form__label">Заголовок<sup>*</sup></label>
            <input type="text" class="form__input" id="title" v-model="boardAddForm.title" :class="{ 'is-invalid': boardAddForm.errors.has('title') }">
            <HasError :form="boardAddForm" field="title"></HasError>
        </div>
        <!-- Статичное поле для выбора категории -->
        <div class="form__item form__item--msg">
            <label class="form__label">Категория<sup>*</sup></label>
            <select class="form__select select" v-model="boardAddForm.category" @change="loadSubcategories"
                    :class="{ 'is-invalid': boardAddForm.errors.has('category') }">
                <option value="">Выберите категорию</option>
                <option v-for="(category, index) in staticCategories" :key="index" :value="category.id">
                    {{ category.title }}
                </option>
            </select>
            <HasError :form="boardAddForm" field="category"></HasError>
        </div>


        <!-- Поле для выбора подкатегории -->
        <div class="form__item form__item--msg" v-if="subcategories.length > 0">
            <label class="form__label">Подкатегория<sup>*</sup></label>
            <select class="form__select select" v-model="boardAddForm.subcategory"
                    :class="{ 'is-invalid': boardAddForm.errors.has('subcategory') }">
                <option value="">Выберите подкатегорию</option>
                <option v-for="(subcategory, index) in subcategories" :key="index" :value="subcategory.id">
                    {{ subcategory.title }}
                </option>
            </select>
            <HasError :form="boardAddForm" field="subcategory"></HasError>
        </div>

        <div class="price_from_to_container">
            <div class="form__item price_from">
                <label for="fio" class="form__label">Цена от<sup>*</sup></label>
                <input type="text" class="form__input" id="price_from" v-model="boardAddForm.price_from" :class="{ 'is-invalid': boardAddForm.errors.has('price_from') }">
                <HasError :form="boardAddForm" field="price_from"></HasError>
            </div>

            <div class="form__item price_to">
                <label for="fio" class="form__label">Цена до<sup>*</sup></label>
                <input type="text" class="form__input" id="price_to" v-model="boardAddForm.price_to" :class="{ 'is-invalid': boardAddForm.errors.has('price_to') }">
                <HasError :form="boardAddForm" field="price_to"></HasError>
            </div>
        </div>

        <div class="form__item">
            <label class="form__check check">
                <input type="checkbox" class="check__input" value="true" v-model="boardAddForm.is_negotiable">
                <span class="check__txt">Договорная</span>
            </label>
        </div>

        <div class="form__item">
            <label class="form__label">Город</label>
            <select class="form__select select" v-model="boardAddForm.city">
                <option v-for="(city, index) in filteredCities" :key="index" :value="city.id">{{ city.title }}</option>
            </select>
            <HasError :form="boardAddForm" field="city"></HasError>
        </div>

       

        <div class="form__item">
            <label for="msg" class="form__label">Описание<sup>*</sup></label>
            <textarea class="form__textarea" id="msg" v-model="boardAddForm.description"
                      :class="{ 'is-invalid': boardAddForm.errors.has('description') }"></textarea>
            <HasError :form="boardAddForm" field="description"></HasError>
        </div>
        <div class="form__item">
            <label for="email" class="form__label">Электронная почта <sup>*</sup></label>
            <input type="email" class="form__input" id="email" placeholder="Напишите адрес своей электронной почты"
                   v-model="boardAddForm.email" :class="{ 'is-invalid': boardAddForm.errors.has('email') }">
            <HasError :form="boardAddForm" field="email"></HasError>
        </div>

        <div class="form__item">
            <div class="g-recaptcha" :data-sitekey="siteKey" data-expired-callback="recaptcha_expired"
                 data-callback="recaptcha_callback"></div>
        </div>

         <!-- Встроенное поле для ввода номера телефона -->
         <PhoneInputComponent v-model:phone="boardAddForm.phone" v-model:phone_code="boardAddForm.phone_code" />

<!-- Встроенное поле для SMS-кода -->
<SmsCodeComponent 
   :phone="boardAddForm.phone" 
   :phone_code="boardAddForm.phone_code" 
   @update:smsCode="boardAddForm.sms_code = $event"
   @update:encryptedSmsCode="boardAddForm.encrypted_sms_code = $event" 
/>
        <hr>
        <!---<div class="form__item">
            <label class="form__check check">
                <input type="checkbox" class="check__input" checked v-model="agreeWithTerm">
                <span class="check__txt">Я согласен с обработкой <a href="#">персональных данных</a></span>
            </label>
        </div>-->
        <div class="form__item">
            <button class="form__btn btn btn--green" type="submit"
                    :disabled="!isAvailableToSendSMS || !isSumbitEnable || !agreeWithTerm">ОТПРАВИТЬ
            </button>
        </div>
    </form>
</template>

<script>
import { Form } from 'vform';
import PhoneInputComponent from './PhoneInputComponent.vue';
import SmsCodeComponent from './SmsCodeComponent.vue';

export default {
    name: "BoardAddForm",
    components: {
        PhoneInputComponent,
        SmsCodeComponent
    },
    props: ['is-mobile', 'cities', 'categories'],
    data() {
        return {
            siteKey: process.env.GOOGLE_RECAPTCHA_SITE_KEY,

            boardAddForm: new Form({
                token: '',
                id: null,
                title: null,
                category: '',
                subcategory: null,
                price_from: null,
                price_to: null,
                is_negotiable: null,
                city: '',
                description: null,
                phone: '',
                phone_code: "+7",
                sms_code: '',
                encrypted_sms_code: '',
                agree: true
            }),

            //isSumbitEnable: false,
             staticCategories: [
                { id: 5, title: 'Работа' },
                { id: 13, title: 'Найти жилье' },
                ],
            subcategories: [], 
            enableSmsCodeField: false,

            agreeWithTerm: true,
        }
    },
    methods: {
        loadSubcategories() {
            this.boardAddForm.subcategory = null; // Сбрасываем подкатегорию

            if (this.boardAddForm.category) {
                // Загружаем подкатегории для выбранной категории
                axios.get(`/api/subcategories?category_id=${this.boardAddForm.category}`)
                    .then(response => {
                        this.subcategories = response.data;
                    })
                    .catch(error => {
                        console.error('Ошибка загрузки подкатегорий:', error);
                    });
            } else {
                this.subcategories = [];
            }
        },

        SendBoardAddForm() {
    if (this.isSumbitEnable) {
        this.isSumbitEnable = false; // Блокируем кнопку повторной отправки

        // Отправляем форму на сервер
        this.boardAddForm.post('/send-board-add-form')
            .then(response => {
                if (response.data.status) {
                    this.$snotify.success(response.data.message, {
                        timeout: 3000,
                    }).on("destroyed", () => {
                        this.enableSmsCodeField = false;
                        this.boardAddForm.reset();
                        this.boardAddForm.clear(); // Очищаем ошибки
                    });
                } else {
                    // Если сервер вернул status: false
                    this.isSumbitEnable = true; // Разблокируем кнопку при ошибке
                    this.$snotify.warning(response.data.message, {
                        timeout: 3000,
                    });
                }
            })
            .catch(error => {
                this.isSumbitEnable = true; // Разблокируем кнопку при ошибке

                // Обрабатываем ошибку 422
                if (error.response && error.response.status === 422) {
                    // Проверяем наличие ошибок валидации в ответе
                    if (error.response.data.errors) {
                        // Если есть ошибки валидации, устанавливаем их в форме
                        this.boardAddForm.errors.set(error.response.data.errors);

                        // Проходим по ошибкам и выводим каждую в виде уведомления
                        Object.values(error.response.data.errors).forEach(errorMessage => {
                            this.$snotify.error(errorMessage[0], {
                                timeout: 3000,
                            });
                        });
                    } else if (error.response.data.message) {
                        // Если есть общее сообщение об ошибке
                        this.$snotify.error(error.response.data.message, {
                            timeout: 3000,
                        });
                    }
                } else if (error.response && error.response.data && error.response.data.message) {
                    // Обрабатываем другие ошибки (например, неверный СМС код)
                    this.$snotify.error(error.response.data.message, {
                        timeout: 3000,
                    });
                } else {
                    // Неизвестная ошибка
                    this.$snotify.error('Произошла ошибка при отправке формы.', {
                        timeout: 3000,
                    });
                }
            });
    } else {
        // Если не все обязательные поля заполнены
        this.$snotify.warning('Заполните все обязательные поля и дайте согласие на обработку данных', {
            timeout: 3000,
        });
    }
},

    },

computed: {
        filteredCities() {
            // Создаем копию массива городов, чтобы не мутировать исходный массив
            let citiesCopy = [...this.cities];

            // Отделяем "Москва" и "Санкт-Петербург"
            const topCities = citiesCopy.filter(city => city.title === 'Москва' || city.title === 'Санкт-Петербург');
            const otherCities = citiesCopy.filter(city => city.title !== 'Москва' && city.title !== 'Санкт-Петербург');

            // Сортируем остальные города по алфавиту
            otherCities.sort((a, b) => a.title.localeCompare(b.title));

            // Объединяем массивы, сначала "Москва" и "Санкт-Петербург", затем остальные
            return [...topCities, ...otherCities];
        },
        isSumbitEnable() {
            return (
        this.boardAddForm.title &&
        this.boardAddForm.category &&
        this.boardAddForm.subcategory &&
        (
            (this.boardAddForm.price_from && this.boardAddForm.price_to) || this.boardAddForm.is_negotiable
        ) &&
        this.boardAddForm.city &&
        this.boardAddForm.phone &&
        this.boardAddForm.description &&
        this.boardAddForm.email
    );
        },
        isAvailableToSendSMS() {
            return (
                this.boardAddForm.phone &&
                this.boardAddForm.title &&
                this.boardAddForm.description &&
                this.boardAddForm.city
            );
        },
    }
};
</script>

<style scoped >
    .board-add-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        form {
            background: #FBFBFB;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
            border-radius: 10px;

            select {
                max-width: none;
            }

            .form__phone {
                div {
                    width: 79%;

                    .form__input {
                        max-width: none;
                    }
                }

                select {
                    width: 20%;
                    margin-right: 15px;
                }
            }
        }
    }

    .price_from_to_container {
        display: flex;

        .price_from {
            width: 49%;
            margin-right: 15px;
        }
        .price_to {
            width: 49%;
        }
    }

    ::v-deep .form__label sup {
    color:green;
}

::v-deep .phone-separate {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}
::v-deep .phone-separate-label{
    width: 100%;
}

::v-deep .phone-separate-fields {
    margin-left: 0%;
    width: 100%;
}
::v-deep .phone-separate-fields div {
    width: 100%;
}

::v-deep .phone-separate-selector {
    padding-left: 1em;
    width: 21%;
}

::v-deep .separate-phone-field {
    width: 100%;
}

::v-deep .sms_item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
::v-deep .sms_item .form__label {
    width: 100%;
}
::v-deep .separate-sms-code {
    margin-left: 0%;
}



::v-deep .sms-phone-select {
    width: 14%;
    margin-right: 1px;
    margin-left: 5%;
}

::v-deep .sms-phone-input {
    width: 50%;
}

::v-deep .sms-code-input {
    margin-right: 1% !important;
    margin-left: 6% !important;
    width: 30% !important;
}
</style>