<template>
    <div class="form__item phone-separate">
      <label class="form__label phone-separate-label">Мобильный телефон</label>
      <div class="form__phone phone-separate-fields">
        <select class="form__select phone-separate-selector"  v-model="phoneCodeLocal" @change="changePhoneCode">
          <option value="+7">+7</option>
          <option value="+996">+996</option>
          <option value="+998">+998</option>
          <option value="+992">+992</option>
        </select>
        <div v-if="phoneCodeLocal === '+7'">
          <MaskInput
            type="tel"
            class="form__input separate-phone-field"
            placeholder="(xxx) xxx-xx-xx"
            mask="(###) ###-##-##"
            v-model="phoneLocal"
          />
        </div>
        <div v-if="phoneCodeLocal === '+996'">
          <MaskInput
            type="tel"
            class="form__input separate-phone-field"
            placeholder="(xxx) xx-xx-xx"
            mask="(###) ##-##-##"
            v-model="phoneLocal"
          />
        </div>
        <div v-if="phoneCodeLocal === '+998'">
          <MaskInput
            type="tel"
            class="form__input separate-phone-field"
            placeholder="(xx) xxx-xxxx"
            mask="(##) ###-####"
            v-model="phoneLocal"
          />
        </div>
        <div v-if="phoneCodeLocal === '+992'">
          <MaskInput
            type="tel"
            class="form__input separate-phone-field"
            placeholder="(xx) xxx-xxxx"
            mask="(##) ####-####"
            v-model="phoneLocal"
          />
        </div>
      </div>
      <!-- Удалили <HasError> -->
    </div>
  </template>
  
  <script>
  import { MaskInput } from 'vue-3-mask';
  
  export default {
      name: "PhoneInputComponent",
      components: {
          MaskInput,
      },
      props: {
          phone: {
              type: String,
              required: false,
              default: '',
          },
          phone_code: {                   // Исправлено на snake_case
              type: String,
              required: false,
              default: '+7',
          },
      },
      emits: ['update:phone', 'update:phone_code'],
      data() {
          return {
              phoneLocal: this.phone || '',
              phoneCodeLocal: this.phone_code || '+7',
          };
      },
      watch: {
          phoneLocal(newVal) {
              this.$emit('update:phone', newVal);
          },
          phoneCodeLocal(newVal) {
              this.$emit('update:phone_code', newVal);
              this.phoneLocal = ''; // Очистка номера при смене кода страны
              this.$emit('update:phone', '');
          },
      },
      methods: {
          changePhoneCode() {
              // Очистка номера при смене кода страны
              this.phoneLocal = '';
              this.$emit('update:phone', '');
          },
      },
  };
  </script>
  
  <style scoped>
  .form__phone {
      display: flex;
      align-items: center;
  }
  .form__code input {
      flex: 1;
      margin-right: 10px;
  }
  .form__btn {
      flex-shrink: 0;
  }
  </style>
  