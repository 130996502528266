// // import './bootstrap';
//
// import { createApp } from 'vue'
// import axios from 'axios';
// import { Form, Errors } from 'vform'
//
// import { vMaska } from "maska"
//
// import snotify from 'vue3-snotify';
// import 'vue3-snotify/style';
//
// import { VueReCaptcha } from 'vue-recaptcha-v3'
//
//
// const app = createApp({})
//
// window.axios = axios;
// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
//
// window.Form = Form;
// window.Errors = Errors;
//
// app.use(snotify,{
//     toast: {
//         timeout: 1500,
//         showProgressBar: true,
//         position: "rightTop",
//     }
// });
//
// app.use(VueReCaptcha, { siteKey: '6LcKvWEnAAAAAALt5Yb7uCVTI0H-aOMMj-nZpPpH' })
//
// app.component(Form);
// app.component(Errors);
// app.directive("maska", vMaska);
//
// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => app.component(key.split('/').pop().split('.')[0], files(key).default))
//
// app.mount('#app')

import _ from 'lodash';
import { createApp } from 'vue';
import AboutContactForm from "./components/AboutContactForm.vue";
import WorkInMbForm from "./components/WorkInMbForm.vue";
import LoanAnketa from "./components/LoanAnketa.vue";
import LoanAnketa2 from "./components/LoanAnketa2.vue";
import AboutCeoForm from "./components/AboutCeoForm.vue";
import LoanCalculator from "./components/LoanCalculator.vue";
import LoanConsultationForm from "./components/LoanConsultationForm.vue";
import BoardAddForm from "./components/BoardAddForm.vue";
import PartnerProgramForm from './components/PartnerLoanAdd.vue';
import SmsCodeComponent from './components/SmsCodeComponent.vue';
import PhoneInputComponent from './components/PhoneInputComponent.vue';
import HolidayOfficeSchedule from './components/HolidayOfficeSchedule.vue';

import snotify from 'vue3-snotify';
import 'vue3-snotify/style';
import { MaskInput } from 'vue-3-mask';

import {Errors, Form} from "vform";
import {
    Button,
    HasError,
    AlertError,
    AlertErrors,
    AlertSuccess
} from 'vform/src/components/bootstrap5'

import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { isMobile } from 'mobile-device-detect';
require('./map');




const app = createApp({
    components: {
        MaskInput,
        LoanAnketa,
        LoanAnketa2,
        AboutContactForm,
        WorkInMbForm,
        AboutCeoForm,
        LoanCalculator,
        LoanConsultationForm,
        BoardAddForm,
        PartnerProgramForm,
        SmsCodeComponent,
        PhoneInputComponent,
        HolidayOfficeSchedule,
        
    }
})


window._ = _;
window.Form = Form;
window.Errors = Errors;
window.axios = axios;
window.isMobile = isMobile;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

app.use(snotify,{
    toast: {
        timeout: 1500,
        showProgressBar: true,
        position: "rightTop",
    }
});
app.component(HasError.name, HasError);

app.use(VueSweetalert2);
app.use(VueReCaptcha, { siteKey: process.env.GOOGLE_RECAPTCHA_SITE_KEY }); // Google reCaptcha v3 (for prod)- Site Key

app.component('MaskInput', MaskInput);

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

let token = document.head.querySelector('meta[name="csrf-token"]')

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}

app.mount('#app')

