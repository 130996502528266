<template>
    <form @submit.prevent="sendAnketa" class="form form--get-loan">
        <div class="form__item">
            <label for="fio" class="form__label">Фамилия Имя Отчество</label>
            <input type="text" class="form__input" id="fio" v-model="loanForm.client_full_name" required>
            <has-error :form="loanForm" field="client_full_name"></has-error>
        </div>
        <div class="form__item">
            <label for="date" class="form__label">Дата рождения</label>
            <input type="text" class="form__input" id="date" v-model="loanForm.date_of_birthday" placeholder="__.__.____" v-maska data-maska="##.##.####">
            <has-error :form="loanForm" field="date_of_birthday"></has-error>
            <p class="form__info">Пожалуйста, введите дату в формате ДД.ММ.ГГГГ</p>
        </div>
        <div class="form__item">
            <label class="form__label">Гражданство</label>
            <select class="form__select select" v-model="loanForm.citizenship">
                <option value="Российская Федерация">Российская Федерация</option>
                <option value="Узбекистан">Узбекистан</option>
                <option value="Таджикистан">Таджикистан</option>
                <option value="Кыргызстан">Кыргызстан</option>
            </select>
            <has-error :form="loanForm" field="citizenship"></has-error>
        </div>
        <div class="form__item">
            <label class="form__label">Язык</label>
            <select class="form__select select" v-model="loanForm.language">
                <option value="Русский">Русский</option>
                <option value="Узбекский">Узбекский</option>
                <option value="Таджикский">Таджикский</option>
                <option value="Кыргызский">Кыргызский</option>
            </select>
            <has-error :form="loanForm" field="language"></has-error>
        </div>
        <div class="form__item">
            <label for="place" class="form__label">Проживание</label>
            <input type="text" class="form__input" id="place" required v-model="loanForm.address">
        </div>
        <div class="form__item">
            <label class="form__label">Пол</label>
            <div class="form__choice">
                <label class="form__radio radio">
                    <input type="radio" class="radio__input" name="sex" value="Мужской" v-model="loanForm.gender">
                    <span class="radio__txt">Мужской</span>
                </label>
                <label class="form__radio radio">
                    <input type="radio" class="radio__input" name="sex" value="Женский" v-model="loanForm.gender">
                    <span class="radio__txt">Женский</span>
                </label>
            </div>
            <has-error :form="loanForm" field="gender"></has-error>
        </div>
        <div class="form__item">
            <label class="form__label">Выберите цель займа</label>
            <div class="form__choice">
                <label class="form__radio radio">
                    <input type="radio" class="radio__input" name="target" value="На документы"  v-model="loanForm.loan_purpose">
                    <span class="radio__txt">На документы</span>
                </label>
                <label class="form__radio radio">
                    <input type="radio" class="radio__input" name="target" value="Потребительский"  v-model="loanForm.loan_purpose">
                    <span class="radio__txt">Потребительский</span>
                </label>
                <label class="form__radio radio">
                    <input type="radio" class="radio__input" name="target" value="На покупку авто" v-model="loanForm.loan_purpose">
                    <span class="radio__txt">На покупку авто</span>
                </label>
            </div>
            <has-error :form="loanForm" field="loan_purpose"></has-error>
        </div>
        <div class="form__item">
            <label class="form__label">Где вам удобно встретиться со специалистом?</label>
            <div class="form__choice">
                <label class="form__radio radio">
                    <input type="radio" class="radio__input" name="where" value="В офисе М Булак" v-model="loanForm.meeting_point">
                    <span class="radio__txt">В офисе М Булак</span>
                </label>
                <label class="form__radio radio">
                    <input type="radio" class="radio__input" name="where" value="У себя дома" v-model="loanForm.meeting_point">
                    <span class="radio__txt">У себя дома</span>
                </label>
                <label class="form__radio radio">
                    <input type="radio" class="radio__input" name="where" value="На работе" v-model="loanForm.meeting_point">
                    <span class="radio__txt">На работе</span>
                </label>
            </div>
            <has-error :form="loanForm" field="meeting_point"></has-error>
        </div>
        <div class="form__item">
            <label class="form__label">Мобильный телефон</label>
            <div class="form__phone">
                <select class="form__select" v-model="loanForm.phone_code">
                    <option value="+7">+7</option>
                    <option value="+996">+996</option>
                    <option value="+998">+998</option>
                    <option value="+992">+992</option>
                </select>
                <input type="tel" class="form__input" @keyup="phoneLengthCount()" placeholder="(xxx) xx xx xx" v-maska data-maska="(###) ## ## ##" v-model="loanForm.phone">
            </div>
        </div>
        <div class="form__item">
            <label for="code" class="form__label">Подтвердите, что вы не робот</label>
            <div class="form__code">
                <input type="text" class="form__input" id="code" required>
                <button class="form__btn btn sms_code_button" type="button" @click="SendSmsCode" :class="{ blue_border : !isSubmitEnable }" :disabled="phoneLength < 11" v-if="!isSubmitEnable">Получить код</button>
            </div>
            <p class="form__info">Вы получите СМС с кодом подтверждения на указанный мобильный телефон</p>
        </div>
        <div class="form__item">
            <div class="g-recaptcha" :data-sitekey="siteKey" data-expired-callback="recaptcha_expired" data-callback="recaptcha_callback"></div>
        </div>
        <hr>
        <div class="form__item">
            <label class="form__check check">
                <input type="checkbox" class="check__input" checked>
                <span class="check__txt">Я согласен с обработкой <a href="#">персональных данных</a></span>
            </label>
        </div>
        <div class="form__item">
            <button class="form__btn btn btn--green" type="submit"  @click="SendAnketaForm" :disabled="!agreeWithTerm" v-if="isSubmitEnable">ОТПРАВИТЬ</button>
        </div>
    </form>
</template>

<script>
export default {
    name: "LoanAnketa",
    data() {
        return {
            siteKey: process.env.GOOGLE_RECAPTCHA_SITE_KEY,

            loanForm: new Form({
                id: null,
                client_full_name: null,
                date_of_birthday: null,
                citizenship: 'Российская Федерация',
                language: 'Русский',
                address: 'Москва',
                gender: null,
                loan_purpose: null,
                meeting_point: null,
                phone: null,
                phone_code: "+7",
                sms_code: null,
                token: ''
            }),

            phoneLength: 0,

            isSubmitEnable: false,
            enableSmsCodeField: false,
            agreeWithTerm: false,
        }
    },
    methods: {
        phoneLengthCount() {
            this.phoneLength = this.loanForm.phone.length;
        },

        SendSmsCode () {
            this.$recaptcha().then((token) => {
                this.loanForm.token = token;
                this.loanForm.post('/loans/send-code')
                    .then(response => {
                        console.log(response);
                        // if (response.data.result === '0') {
                        //     this.$snotify.success("Код отправлен в СМС", {
                        //         timeout: 3000,
                        //     }) .on("destroyed", () =>  {
                        //         this.enableSmsCodeField = true;
                        //         this.isSumbitEnable = true;
                        //         $('.sms_code_button').remove();
                        //     });
                        // }
                        // if(response.data.result === '1') {
                        //     this.$swal.fire({
                        //         title: data.message,
                        //         type: 'warning'
                        //     });
                        // }
                    })
            })
        },
    }
}
</script>
